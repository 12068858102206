import React, {useEffect, useRef} from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import {HtmlVideo} from "../Video";
import * as Markdown from 'react-markdown';
import {customColor, collectionDisplay} from "../../lib/helpers";
import style from "./Content.module.css";

const TwoColumnContent = ({ title, copy, image, textAlign, displayWidth, sectionColor, slug }) => {


    const sectionImage = useStaticQuery(graphql`
        query sectionImage {
          allContentfulSectionItem {
            edges {
              node {
                slug
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);

    const data = useRef(false)
    const backgroundColor = useRef()

    data.current = sectionImage.allContentfulSectionItem.edges.find(d => d.node.slug === slug)
    backgroundColor.current = customColor(sectionColor)
    backgroundColor.current = backgroundColor.current !== "None" ? backgroundColor.current : null

    // useEffect(
    //     () => {
    //         const hrefs = document.getElementsByTagName('A');
    //         console.log("FORMAT BUTTON", hrefs)
    //         for(let val of Object.values(hrefs)) {
    //             if(val.getAttribute('title') && val.getAttribute('title').toLowerCase().includes('button')) {
    //                 val.classList.add(`uk-button`, `uk-border-pill`, `${style.linkToButton}`)
    //             }
    //         }
    //
    //     }, [data.current]
    // )

    if(!data.current) return <div />

    return (
        <section className={`${style.bto__sectionItem} uk-section uk-section-xsmall uk-margin-large ${backgroundColor.current ? backgroundColor.current : ""}`}>
            <div className={`uk-container uk-container-${displayWidth}`}>
                <div className="uk-grid uk-grid-large" data-uk-grid>
                    <div className={`uk-width-3-5@m ${textAlign && !textAlign ? "uk-flex-right" : "uk-flex-left"}`}>
                    {data.current.node.image.localFile.childImageSharp ?
                        <Img
                            fluid={data.current.node.image.localFile.childImageSharp.fluid}
                            alt={title}
                            objectFit="cover"
                            objectPosition="50% 50%"
                             />
                    :
                        <HtmlVideo videoSrcURL={image.fields.file.url} />
                    }
                     </div>
                     <div className={`uk-width-expand uk-flex uk-flex-middle ${textAlign && textAlign && "uk-flex-first@m"}`}>
                        <div>
                            <h2>{title}</h2>
                            <div>
                                <Markdown
                                    source={copy}
                                    escapeHtml={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TwoColumnContent;
