import React, { useEffect, useState } from "react";
import { navigate, useLocation } from "@reach/router";
import axios from "axios"
import { useForm } from "react-hook-form";
import * as Markdown from 'react-markdown';
import {customColor} from "../../lib/helpers";
import Modal from "../Modal";
import style from "./Contact.module.css";
import moment from 'moment';

async function postForm(body) {
    const url = process.env.GATSBY_CONTACT_FORM_ENDPOINT;
    const result = await axios.post(url, body, {
            headers: {
                'Accept': 'application/json'
            }
        })
    const res = await result.data;
    return res;
}

const formType = type => {
    switch(type) {
        case "campaign":
            return `Contact from campaign: `;
        case "up-adviser":
            return `Up Adviser contact`;
        case "support":
            return `Support request`;
        default:
            return `General contact`;
    }
}

const Contact = ({ headline, slug, displayWidth, sectionColor, type, formFields, ...props }) => {
    const { pathname, search } = useLocation()
    let backgroundColor = customColor(sectionColor)
    const campaign = search.includes('utm_campaign') ? search.split('utm_campaign=')[1].split('&')[0] : false;

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        errors,
        watch,
        formState
    } = useForm({
        mode: "all",
    });

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalShown, setIsModalShown] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        subject: "Subject line",
        content: "Message content"
    })

    useEffect(
        () => {
            if(document.getElementById("SubmitContactForm") && document.getElementById("SubmitContactForm").length) {
                document.getElementById("SubmitContactForm").disabled = true;
            }
        }, [isSubmitting]
    )

    const getForm = fields => {
        switch(fields) {
            case "Short":
                return <ShortForm
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    formState={formState}
                    isSubmitting={isSubmitting}
                    slug={slug}
                    backgroundColor={backgroundColor}
                    displayWidth={displayWidth}
                    headline={headline}
                    submit={submit}
                    type={type}
                    campaign={campaign} />;
            default:
                return <LongForm
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    formState={formState}
                    isSubmitting={isSubmitting}
                    slug={slug}
                    backgroundColor={backgroundColor}
                    displayWidth={displayWidth}
                    headline={headline}
                    submit={submit}
                    type={type}
                    campaign={campaign} />;
        }
    }

    const resetForm = () => {
        setIsModalShown(false)
    }

    const submit = async (data, e) => {
        e.preventDefault()
        setIsSubmitting(true)
        const now = moment().format('DD MMM, YYYY')
        const body = {...data, timestamp: now}

        postForm(body)
        .then(function (response) {
            setIsSubmitting(false)
            console.log("TOPIC", data.topic)
            navigate(`/up-adviser-thank-you/`)
        })
        .catch(function (error) {
            console.log("FIRST CATCH ERROR", error.response)
            setResponseMessage({
                subject: "There was a problem.",
                content: "There was an error, please try again."
            })
            setIsModalShown(true)
            setIsSubmitting(false)
            document.getElementById("contactForm").reset();
        })
    }

    return (
        <>
        {getForm(formFields)}
        <Modal
            isShown={isModalShown}
            style={{borderStyle: "none"}}>
            <div className="uk-card uk-card-default">
                <div className="uk-card-header">
                    <h4>{responseMessage.subject}</h4>
                </div>
                <div className="uk-card-body">
                    <p>{responseMessage.content}</p>
                    <div className="uk-text-center uk-margin-large">
                        <button
                            className="uk-button uk-button-primary uk-border-pill"
                            onClick={resetForm}>
                            Okay
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}

const ShortForm = ({
    register,
    handleSubmit,
    errors,
    formState,
    isSubmitting,
    slug,
    backgroundColor,
    displayWidth,
    headline,
    submit,
    type,
    campaign}) => {

    return (
        <section id={slug} className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${displayWidth} uk-margin-auto-left uk-margin-auto-right`}>
                <div className="uk-grid uk-child-width-1-2@s" data-uk-grid>
                <div className="uk-margin-large-bottom">
                    <Markdown
                        source={headline}
                        escapeHtml={false}
                    />
                </div>
                <div>
                    <form id="contactForm"
                        className="default-form"
                        onSubmit={(e) => handleSubmit(submit)(e)}>
                    {type ?
                        <input
                            id="topic"
                            name="topic"
                            ref={register()}
                            type="hidden"
                            value={`${formType(type)}${type==='campaign' && campaign}`}
                            className={`uk-hidden`}
                        />
                        :
                        null}
                        <fieldset className="uk-fieldset uk-text-left">
                            <div className="uk-margin-small-top">
                                <label htmlFor="first_name" className={`uk-text-small ${errors.first_name ? "uk-text-danger" : "uk-text-muted"}`}>
                                {errors.first_name ?
                                <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                <small className="uk-text-danger uk-display-inline">*</small>} Your name  {errors.first_name?.type === "required" && "is required"}
                                    <input
                                        id="first_name"
                                        name="first_name"
                                        ref={register({required: true})}
                                        type="text"
                                        className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        required
                                    />
                                </label>
                            </div>

                            <div className="uk-margin-small-top">
                                <label htmlFor="email" className={`uk-text-small ${errors.email ? "uk-text-danger" : "uk-text-muted"}`}>
                                {errors.email ?
                                <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                <small className="uk-text-danger uk-display-inline">*</small>} Your email address {
                                    errors.email?.type === "required" && "is required" || errors.email && errors.email.message
                                }
                                    <input
                                        id="email"
                                        name="email"
                                        ref={register({
                                          required: true,
                                          pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "is invalid"
                                          }
                                        })}
                                        type="text"
                                        className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        required
                                    />
                                </label>
                            </div>

                            <div className="uk-text-center uk-margin-large-top">
                                {!formState.isValid  ?
                                    <p className="uk-text-uppercase uk-text-small">Fill out required fields</p>
                                :
                                    isSubmitting ?
                                    <p className="uk-text-uppercase uk-text-small">Just a moment</p>
                                    :
                                    <button
                                        id="SubmitContactForm"
                                        type="submit"
                                        className={`uk-button uk-button-secondary uk-button-large uk-border-pill`}
                                        >
                                        Send your message
                                    </button>
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
                </div>
            </div>
        </section>
    )
}

const LongForm = ({
    register,
    handleSubmit,
    errors,
    formState,
    isSubmitting,
    slug,
    backgroundColor,
    displayWidth,
    headline,
    submit,
    type,
    campaign}) => {

    return (
        <section id={slug} className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${displayWidth} uk-margin-auto-left uk-margin-auto-right`}>
                <div className="uk-margin-large-bottom">
                    <Markdown
                        source={headline}
                        escapeHtml={false}
                    />
                </div>
                <form id="contactForm"
                    className="default-form"
                    onSubmit={(e) => handleSubmit(submit)(e)}>
                {type ?
                    <input
                        id="topic"
                        name="topic"
                        ref={register()}
                        type="hidden"
                        value={`${formType(type)}${type==='campaign' && campaign}`}
                        className={`uk-hidden`}
                    />
                    :
                    null}
                    <fieldset className="uk-fieldset uk-text-left">
                        <div className="uk-grid uk-grid-medium uk-child-width-1-2@s" data-uk-grid>
                            <div className="uk-margin-small-top">
                                <label htmlFor="first_name" className={`uk-text-small ${errors.first_name ? "uk-text-danger" : "uk-text-muted"}`}>
                                {errors.first_name ?
                                <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                <small className="uk-text-danger uk-display-inline">*</small>} First name {errors.first_name?.type === "required" && "is required"}
                                    <input
                                        id="first_name"
                                        name="first_name"
                                        ref={register({required: true})}
                                        type="text"
                                        className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        required
                                    />
                                </label>
                            </div>

                            <div className="uk-margin-small-top">
                                <label htmlFor="last_name" className={`uk-text-small ${errors.last_name ? "uk-text-danger" : "uk-text-muted"}`}>
                                {errors.last_name ?
                                <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                <small className="uk-text-danger uk-display-inline">*</small>} Last name {errors.last_name?.type === "required" && "is required"}
                                    <input
                                        id="last_name"
                                        name="last_name"
                                        ref={register({required: true})}
                                        type="text"
                                        className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        required
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="uk-margin-small-top">
                            <label htmlFor="email" className={`uk-text-small ${errors.email ? "uk-text-danger" : "uk-text-muted"}`}>
                            {errors.email ?
                            <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                            :
                            <small className="uk-text-danger uk-display-inline">*</small>} Email address {
                                errors.email?.type === "required" && "is required" || errors.email && errors.email.message
                            }
                                <input
                                    id="email"
                                    name="email"
                                    ref={register({
                                      required: true,
                                      pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "is invalid"
                                      }
                                    })}
                                    type="text"
                                    className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                    autoComplete="off"
                                    autoCorrect="off"
                                    required
                                />
                            </label>
                        </div>
                        <div className="uk-margin-small-top">
                            <label htmlFor="organization" className={`uk-text-small uk-text-muted`}>
                                Company or organization (optional)
                                <input
                                    id="organization"
                                    name="organization"
                                    ref={register}
                                    type="text"
                                    className="uk-input uk-form-large uk-border-rounded"
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </label>
                        </div>

                        <div className="uk-margin-small-top">
                            <label htmlFor="message" className={`uk-text-small ${errors.message ? "uk-text-danger" : "uk-text-muted"}`}>
                            {errors.message ?
                            <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                            :
                            <small className="uk-text-danger uk-display-inline">*</small>} Your message
                                <textarea
                                    id="message"
                                    name="message"
                                    ref={register({required: true})}
                                    rows="3"
                                    className={`uk-textarea uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                    maxLength="1000"
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </label>
                        </div>

                        <div className="uk-text-center uk-margin-large">
                            {!formState.isValid  ?
                                <p className="uk-text-uppercase uk-text-small">Fill out required fields</p>
                            :
                                isSubmitting ?
                                <p className="uk-text-uppercase uk-text-small">Just a moment</p>
                                :
                                <button
                                    id="SubmitContactForm"
                                    type="submit"
                                    className={`uk-button uk-button-secondary uk-button-large uk-border-pill`}
                                    >
                                    Send your message
                                </button>
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        </section>
    )
}

export default Contact;
