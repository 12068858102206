import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';
import style from "./Hero.module.css";

const HeroImage = ({data, locale}) => {

    const heroCtaMedia = useStaticQuery(graphql`
        query heroCtaMedia {
          allContentfulHeroCta {
            edges {
              node {
                sectionId
                imageFile {
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(maxWidth: 2000, quality: 70) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `);

    const {
        headline,
        subHeadline,
        sectionId
    } = data
    const leader = subHeadline ? subHeadline : ""
    const media = heroCtaMedia.allContentfulHeroCta.edges.find(d => d.node.sectionId === sectionId)

    return (
        <BackgroundImage
                Tag="div"
                fluid={media.node.imageFile.localFile.childImageSharp.fluid}
                preserveStackingContext={true}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: "rgba(29, 38, 54, 0)",
                    backgroundPosition: `center center`,
                    display: `flex`,
                    alignItems: `center`,
                    height: `100%`
                  }}
              >
              <div id={sectionId} className="uk-container uk-position-relative uk-light">
                  <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                      <div
                          style={{minWidth: "80%"}}
                          className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                          data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                          <div
                              className={`${style.heroCopy} uk-width-1-2@s`}
                              data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                              <h1>{headline}</h1>
                              <div>
                                  <Markdown
                                      source={leader}
                                      escapeHtml={false}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </BackgroundImage>
    )
}

export default HeroImage;
