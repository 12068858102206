import React, {useEffect, useState} from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// import Img from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import * as Markdown from 'react-markdown';
import {customColor, collectionDisplay} from "../../lib/helpers";
import Testimonials from "./Testimonials";
import MediaSection from "./MediaSection";
import style from "./Content.module.css";
import eye from "../../images/icons/Eye.png";

import UIKit from "uikit";
import Player from '@vimeo/player';

const CardCollection = ({ headline, subHeadline, cards, slug, displayFormat, displayWidth, sectionColor, ...props }) => {

    const cardImages = useStaticQuery(graphql`
        query cardImages {
            allContentfulCardCollection {
                edges {
                    node {
                        slug
                        cards {
                            ...on ContentfulBenefitsItem {
                                slug
                                createdAt
                                title
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                image {
                                    title
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulBlogPost {
                                slug
                                createdAt
                                title
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                image {
                                    title
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulProductItem {
                                slug
                                createdAt
                                title
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                image {
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulSectionItem {
                                id
                                slug
                                title
                                createdAt
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                image {
                                    title
                                    localFile {
                                        childImageSharp {
                                            fluid(maxWidth: 800) {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                        publicURL
                                    }
                                }
                            }
                            ...on ContentfulMediaSection {
                                description
                                subHeadline
                                displayWidth
                                sectionColor
                                mediaPath
                                slug
                            }
                            ...on ContentfulTestimonial {
                                id
                                slug
                                source
                                role
                                copy {
                                    childMarkdownRemark {
                                        html
                                    }
                                    copy
                                }
                            }
                        }
                    }
                }
            }
        }
      `);

    const dis = collectionDisplay(displayFormat)
    const data = cardImages.allContentfulCardCollection.edges.find(d => d.node.slug === slug)
    let backgroundColor = customColor(sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null
    const collection = cards.map(c => c.fields)
    const [videoPlay, setVideoPlay] = useState(false)

    useEffect(
        () => {
            const mediaPlayer = document.querySelectorAll('.mediaPlayerIframe');
            [...mediaPlayer].forEach((p, i) => {
                p.classList.add(`player${i}`)
                let el = document.querySelector(`.player${i}`)
                let player = new Player(el);

                player.on('play', function() {
                    setVideoPlay(true)
                });

                player.on('pause', function() {
                    setVideoPlay(false)
                });
            });
        }, []
    )

    useEffect(
        () => {
            const mediaSliderWrap = document.getElementById('mediaSectionSlider')
            const $Slider = UIKit.slider(mediaSliderWrap);
            if(videoPlay) {
                $Slider.stopAutoplay()
            } else {
                $Slider.startAutoplay()
            }
        }, [videoPlay]
    )

    const ImageCardCollection = card => {
        return (
            <div className={`uk-container uk-container-${displayWidth} uk-position-relative`}>
                <div data-uk-slider={dis.carousel ? "autoplay: true" : "false"}>
                    <div
                        className={`${dis.carousel ? "uk-slider-items" : ""} ${style.cardCollection} uk-grid ${dis.count ? "uk-child-width-1-1" : (collection.length <= 2 || displayWidth === "xsmall") ? "uk-child-width-1-2@m" : "uk-child-width-1-3@m"} uk-grid-match`}
                        data-uk-grid
                        data-uk-height-match="target: .uk-card-body">
                        {cards.map((card, i) =>
                        <div id={card.fields.slug} key={card.fields.slug}
                            className={`${collection.length <= 2 ? style.cardItemLarge : (displayWidth === "small" || displayWidth === "xsmall") ? style.cardItemSmall : style.cardItem} uk-card uk-card-small`}>
                            {data.node.cards[i].image ?
                            <div className="uk-card-media-top">
                                 <Img
                                     fluid={data.node.cards[i].image.localFile.childImageSharp.fluid}
                                     alt={card.fields.image.fields.title}
                                     objectFit="cover"
                                     objectPosition="50% 50%"
                                     className="uk-border-rounded"
                                      />
                            </div>
                            : null}
                            <div
                                className={`uk-card-body`}
                                >
                                {card.fields.title ?
                                <h3 className="uk-margin-remove-vertical">
                                        {card.fields.title}
                                </h3>
                                : null}
                                {card.fields.copy ?
                                <Markdown
                                    source={card.fields.copy}
                                    escapeHtml={false}
                                />
                                : null}

                                {card.sys.contentType.sys.id === "blogPost" ?
                                    <strong><Link to={`/blog/${card.fields.slug}`}>Read more</Link></strong>
                                :null}

                            </div>
                        </div>
                        )}
                    </div>
                    <div className="uk-margin-large-top">
                        <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <section id="sliderContainer" className={`uk-section ${backgroundColor ? backgroundColor : ""}`}>
            <div className={`uk-container uk-container-${displayWidth} uk-position-relative`}>
                {headline ?
                    <div className="uk-text-center uk-margin-large-top uk-margin-medium-bottom uk-margin-auto-left uk-margin-auto-right uk-container-xsmall">
                        <h2 className="uk-margin-remove-top uk-margin-small-bottom uk-display-block">
                            {headline}
                        </h2>
                        {subHeadline ? <p className="uk-h4 uk-margin-remove-top">{subHeadline}</p> : null}
                    </div>
                : null
                }
            </div>
            {cards[0].sys.contentType.sys.id === "testimonial" ?
            <Testimonials cards={cards} />
            :
            cards[0].sys.contentType.sys.id === "mediaSection" ?
            <div className="uk-container uk-container-small uk-child-width-2-3@m uk-margin-large-bottom uk-margin-large-top">
                <div id="mediaSectionSlider"
                    className="uk-position-relative uk-text-center"
                    data-uk-slider>
                    <ul
                        className={`${style.cardCollection} uk-slider-items uk-child-width-1-1 uk-flex-middle`}
                        data-uk-height-match="target: .media-title">
                        {cards.map((card, i) =>
                            <li key={card.fields.slug}>
                                <div
                                    className="media-title uk-margin-medium-bottom uk-width-4-5@m uk-margin-auto-left uk-margin-auto-right uk-flex uk-flex-bottom">
                                    <div>
                                        <h2 className="uk-margin-remove">{card.fields.description}</h2>
                                        <p className="uk-text-muted uk-margin-remove-bottom">{card.fields.subHeadline}</p>
                                    </div>
                                </div>
                                <div style={{position: "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden", zIndex: "5"}}>
                                    <iframe
                                        className="mediaPlayerIframe"
                                        style={{position: "absolute", top: "0", left: "0", height: "100%", width: "100%"}}
                                        src={card.fields.mediaPath}
                                        frameBorder="0" />
                                </div>
                            </li>
                        )}
                    </ul>
                    <div className="uk-margin-top">
                        <ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
                    </div>
                </div>
            </div>
            :
            <ImageCardCollection cards={cards} />
            }
        </section>
    );
}

export default CardCollection;
